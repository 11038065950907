import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import IntegrationGitLab from '../components/PersonalIntegrations/gitLab'
import { Helmet } from "react-helmet";

// const content = `Project management is transparent with DueFocus time tracker. One thing left is to track working hours in GitLab and analyze activities.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const GitLab = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>GitLab Integration with DueFocus Time Tracking Software</title>
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/time-tracking-gitlab/" />
    </Helmet>
    <Header />
    <IntegrationGitLab />
    <Footer />
  </>
)

export default GitLab